import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "1234abcd5678efgh",
    name: "Mahboob Pasha",
    email: "Mahboob123@gmail.com",
    mobileNumber: "8892899808",
    companyName: "AM Furniture",
    productOrService: "Sofa",
    imgSrc: "/assets/sofa4.jpeg", // You need to provide the correct image path
    mainProducts: "Living Room Sofa, Recliner Sofa, Leather Sofa",
    altText: "Sofa - AM Furniture",
    years: "1 YRS",
    location: "Rajkot, Gujarat, India",
    tooltipText: "12 Furniture Street, Rajkot, Gujarat, India",
    rating: "4.8",
    ratingPercent: "96%",
    ratingsCount: "120",
    responseRate: "98%",
  },
  {
    name: "Bharti",
    email: "enterprisekrupa4@gmail.com",
    password: "bhartibharti",
    mobileNumber: "9084829219",
    companyName: "Krupa Enterprises Kaisav",
    productOrService: "Sofa Hardware",
    imgSrc: "/assets/sofa8.jpg",
    mainProducts: "Sofa Hardware, Furniture Accessories, Sofa Fittings",
    altText: "Krupa Enterprises Kaisav - Sofa Hardware",
    years: "1 YRS",
    location: "Aligarh, Uttar Pradesh, India",
    tooltipText: "Krupa Enterprises Kaisav, Aligarh",
    rating: "4.5",
    ratingPercent: "90%",
    ratingsCount: "120",
    responseRate: "85%",
    whatsappConfirmed: true,
  },
];

const SofaProvider = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Living Room Sofa</Link>
                </li>
                <li>
                  <Link to="#">Recliner Sofa</Link>
                </li>
                <li>
                  <Link to="#">Leather Sofa</Link>
                </li>
                <li>
                  <Link to="#">Sectional Sofa</Link>
                </li>
                <li>
                  <Link to="#">Fabric Sofa</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By State</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="state_id"
                  placeholder="Search State"
                  id="state-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="state-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Gujarat</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Rajasthan</Link>
                </li>
                <li>
                  <Link to="#">Karnataka</Link>
                </li>
                <li>
                  <Link to="#">Delhi</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/sofa1.jpeg"
                      width={55}
                      height={55}
                      alt="Living Room Sofa"
                    />
                  </figure>
                  <p className="title">Living Room Sofa</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/sofa2.jpeg"
                      width={55}
                      height={55}
                      alt="Recliner Sofa"
                    />
                  </figure>
                  <p className="title">Recliner Sofa</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/sofa3.jpeg"
                      width={55}
                      height={55}
                      alt="Leather Sofa"
                    />
                  </figure>
                  <p className="title">Leather Sofa</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/sofa4.jpeg"
                      width={55}
                      height={55}
                      alt="Sectional Sofa"
                    />
                  </figure>
                  <p className="title">Sectional Sofa</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/sofa5.jpeg"
                      width={55}
                      height={55}
                      alt="Fabric Sofa"
                    />
                  </figure>
                  <p className="title">Fabric Sofa</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products:</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default SofaProvider;
