import React from "react";
import { Link } from "react-router-dom";
import "./common.css";

const classifiedData = [
  {
    _id: "b1234567890rajmudra",
    name: "Ishwar Bongane",
    email: "ishwarbangone@gmail.com",
    password: "0987654321",
    mobileNumber: "9393199696",
    companyName: "Rajmudra",
    productOrService: "Banana",
    imgSrc: "/assets/banana1.jpeg",
    mainProducts: "Banana",
    altText: "Rajmudra - Banana",
    years: "1 YRS",
    location: "Indapur dist Pune, Maharashtra, India",
    tooltipText: "Rajmudra, Indapur dist Pune, Maharashtra",
    rating: "4.7",
    ratingPercent: "92%",
    ratingsCount: "85",
    responseRate: "88%",
    whatsappConfirmed: true,
  },
  {
    _id: "dipak-vishwas-dhangar-yawal",
    name: "Dipak Vishwas Dhangar",
    email: "abc@gmail.com",
    password: "dipak@123",
    mobileNumber: "7038942812",
    companyName: "Dipak Vishwas Dhangar",
    productOrService: "Banana",
    imgSrc: "/assets/banana2.jpg",
    mainProducts: "Banana",
    altText: "Dipak Vishwas Dhangar - Banana",
    years: "1 YRS",
    location: "Yawal, Maharashtra, India",
    tooltipText: "Dipak Vishwas Dhangar, Banana, Yawal",
    rating: "4.5",
    ratingPercent: "91%",
    ratingsCount: "27",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    _id: "sri-vinayaga-banana-godown",
    name: "Sekar Manickam",
    email: "sekarmanickam123@gmail.com",
    password: "9443426578",
    mobileNumber: "9443426578",
    companyName: "Sri Vinayaga Banana Godown",
    productOrService: "Banana",
    imgSrc: "/assets/banana3.jpg",
    mainProducts: "Banana",
    altText: "Sri Vinayaga Banana Godown - Banana",
    years: "1 YRS",
    location: "Tiruppur, Tamilnadu, India",
    tooltipText: "Sri Vinayaga Banana Godown, Banana, Tiruppur",
    rating: "4.3",
    ratingPercent: "90%",
    ratingsCount: "25",
    responseRate: "92%",
    whatsappConfirmed: true,
  },
  {
    name: "Rahul",
    email: "abc@gmail.com",
    password: "rahul123",
    mobileNumber: "9324576504",
    companyName: "Sasya Enterprises",
    productOrService: "Banana",
    imgSrc: "/assets/banana6.jpg",
    mainProducts: "Bananas, Fresh Fruits, Organic Produce",
    altText: "Sasya Enterprises - Fresh Bananas",
    years: "1 YRS",
    location: "Navi Mumbai, Maharashtra, India",
    tooltipText: "Sasya Enterprises, Navi Mumbai",
    rating: "4.6",
    ratingPercent: "92%",
    ratingsCount: "80",
    responseRate: "89%",
    whatsappConfirmed: true,
  },
  {
    name: "Rahul",
    email: "abc@gmail.com",
    password: "rahul123",
    mobileNumber: "9324576504",
    companyName: "Sasya Enterprises",
    productOrService: "Banana",
    imgSrc: "/assets/bnaana.jpg",
    mainProducts: "Fresh Bananas, Organic Produce, Fruits",
    altText: "Sasya Enterprises - Fresh Bananas",
    years: "1 YRS",
    location: "Nerul, Maharashtra, India",
    tooltipText: "Sasya Enterprises, Nerul",
    rating: "4.3",
    ratingPercent: "88%",
    ratingsCount: "78",
    responseRate: "85%",
    whatsappConfirmed: true,
  },

  // Add more items if needed
];

const BananaComponent = () => {
  return (
    <div className="main-box">
      <aside>
        <div className="flt-box-wrap">
          <div className="flt-box mb-0 flt-head">Filters By</div>
          <div className="flt-box bdrt-0">
            <p className="flt-title">Related Categories</p>
            <div className="flt-content">
              <ul className="flt-list cust-scroll">
                <li>
                  <Link to="#">Apples</Link>
                </li>
                <li>
                  <Link to="#">Oranges</Link>
                </li>
                <li>
                  <Link to="#">Bananas</Link>
                </li>
                <li>
                  <Link to="#">Grapes</Link>
                </li>
                <li>
                  <Link to="#">Mangoes</Link>
                </li>
                <li>
                  <Link to="#">Berries</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="flt-box">
            <p className="flt-title">By State</p>
            <div className="flt-content">
              <div className="flt-search">
                <input
                  type="text"
                  name="state_id"
                  placeholder="Search State"
                  id="state-search-input"
                />
              </div>
              <ul className="flt-list cust-scroll" id="state-lists">
                <li>
                  <Link to="#">All India</Link>
                </li>
                <li>
                  <Link to="#">Gujarat</Link>
                </li>
                <li>
                  <Link to="#">Maharashtra</Link>
                </li>
                <li>
                  <Link to="#">Karnataka</Link>
                </li>
                <li>
                  <Link to="#">Punjab</Link>
                </li>
                <li>
                  <Link to="#">Uttar Pradesh</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </aside>
      <main>
        <section className="section">
          <p className="sect-title">Explore by Categories</p>
          <div className="horprd expcatg" id="expcatg">
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/apple1.jpeg"
                      width={55}
                      height={55}
                      alt="Apples"
                    />
                  </figure>
                  <p className="title">Apples</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/oranges.jpeg"
                      width={55}
                      height={55}
                      alt="Oranges"
                    />
                  </figure>
                  <p className="title">Oranges</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/banana.jpeg"
                      width={55}
                      height={55}
                      alt="Bananas"
                    />
                  </figure>
                  <p className="title">Bananas</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/grapes.jpeg"
                      width={55}
                      height={55}
                      alt="Grapes"
                    />
                  </figure>
                  <p className="title">Grapes</p>
                </div>
              </Link>
            </div>
            <div className="item">
              <Link to="#">
                <div className="horprd-box">
                  <figure>
                    <img
                      src="/assets/mango.jpeg"
                      width={55}
                      height={55}
                      alt="Mangoes"
                    />
                  </figure>
                  <p className="title">Mangoes</p>
                </div>
              </Link>
            </div>
          </div>
        </section>
        <ul className="classfied-wrap">
          {classifiedData.map((item, index) => (
            <li key={index}>
              <div className="classified">
                <div className="prd-info">
                  <div className="prd-box">
                    <img
                      src={item.imgSrc}
                      alt={item.altText}
                      width={250}
                      height={250}
                    />
                  </div>
                </div>
                <div className="cinfo">
                  <div className="cbox">
                    <figure>
                      <span className="cmp-year">{item.years}</span>
                    </figure>
                    <div className="cboxr">
                      <Link to="#" target="_blank">
                        <h4 className="title">{item.companyName}</h4>
                      </Link>
                      <p className="cloc tooltip ellipsis">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={16}
                          height={16}
                          fill="currentColor"
                          className="bi-location"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z" />
                        </svg>
                        {item.location}
                        <span className="tooltiptext">{item.tooltipText}</span>
                      </p>
                      <div className="rating-wrap">
                        <span className="rtbox">{item.rating}</span>
                        <span
                          className="crate"
                          style={{ "--_score": item.ratingPercent }}
                        />
                        <span className="rate-text">
                          {item.ratingsCount} Ratings
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="caddit">
                    <div className="item">
                      <div
                        className="ca-box modal-btn"
                        data-modal="motc"
                        data-src={item.trustCertificateUrl}
                      >
                        <p>
                          <i className="l3icon motc-icon" />
                        </p>
                      </div>
                    </div>
                    <div className="item">
                      <div className="ca-box">
                        <p>
                          <i className="l3icon resp-icon" />
                        </p>
                        <p>
                          <span>Response Rate</span> <b>{item.responseRate}</b>
                        </p>
                      </div>
                      <p>
                        <span>Main Products</span> <b>{item.mainProducts}</b>
                      </p>
                    </div>
                  </div>
                  <div className="caction">
                    <Link to={"/register-buyer"}>
                      <p>Contact Supplier</p>
                    </Link>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </main>
    </div>
  );
};

export default BananaComponent;
