import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Link } from "react-router-dom";
import "swiper/css/pagination";

// import required modules
import "./allcat.css";

const products = [
  {
    id: 1,
    name: "Rice",
    image: "./assets/re-re-rice.jpg",
    link: "/agroproducts?search=rice",
  },
  {
    id: 2,
    name: "Potatoes",
    image: "./assets/r-potato.jpg",
    link: "/potatosupplier?search=potato",
  },
  {
    id: 3,
    name: "Leather Bags",
    image: "./assets/rr-leather.jpg",
    link: "/bagsandbeltdealer?search=Leather%20Bags",
  },
  {
    id: 4,
    name: "Banana Powder",
    image: "./assets/banan-re.jpg",
    link: "/bananapowder?search=banana%20powder",
  },
  {
    id: 5,
    name: "Cable",
    image: "./assets/cable-re.jpg",
    link: "/wiresuppliers?search=wire",
  },
  {
    id: 9,
    name: "curtains",
    image: "./assets/re-curt.jpg",
    link: "/texttiledealer?search=curtains",
  },
  {
    id: 6,
    name: "Suitcase",
    image: "./assets/resuit.jpg",
    link: "/suitcasemanufacturer?search=suitcase",
  },
  {
    id: 7,
    name: "Dry Fruits",
    image: "./assets/redry.jpg",
    link: "/dryfruitsmanu?search=dry",
  },
  { id: 8, name: "needles", image: "./assets/reneedle1.jpg", link: "/" },

  // Add more products as needed
];

const productsx = [
  {
    id: 1,
    name: "Desi-Ghee",
    image: "./assets/re=ghee.jpg",
    link: "/ghee-seller",
  },
  {
    id: 2,
    name: "Safforon",
    image: "./assets/re-saffron.jpg",
    link: "saffron-manufacturer?search=saffron",
  },
  {
    id: 3,
    name: "Wheat",
    image: "./assets/wheet-re.jpg",
    link: "/whearsupplier?search=wheat",
  },
  {
    id: 4,
    name: "Maize Dry Rospe",
    image: "./assets/remaize1.jpg",
    link: "maizemanu?search=maize",
  },
  {
    id: 5,
    name: "Wiremesh",
    image: "./assets/wiremesh1.jpg",
    link: "/wiremesh?search=mesh",
  },
  {
    id: 6,
    name: "Gym Bands",
    image: "./assets/gym-re.jpg",
    link: "/gymequipmentsupplier?search=gym%20equipments",
  },
  {
    id: 7,
    name: "Dresses",
    image: "./assets/dress-re.jpg",
    link: "/garmentdedealer?search=garment",
  },
  { id: 8, name: "needles", image: "./assets/reneedle1.jpg", link: "/" },
  {
    id: 9,
    name: "curtains",
    image: "./assets/re-curt.jpg",
    link: "/texttiledealer?search=curtains",
  },
  // Add more products as needed
];

export default function Allcategory() {
  const [swiperInstance, setSwiperInstance] = useState(null);

  const handleReachEnd = () => {
    if (swiperInstance) {
      const isEnd = swiperInstance.isEnd;
      const navigationButton = document.querySelector(".swiper-button-next");
      if (navigationButton) {
        navigationButton.style.display = isEnd ? "none" : "block";
      }
    }
  };

  const handleReachStart = () => {
    if (swiperInstance) {
      const isBeginning = swiperInstance.isBeginning;
      const navigationButton = document.querySelector(".swiper-button-prev");
      if (navigationButton) {
        navigationButton.style.display = isBeginning ? "none" : "block";
      }
    }
  };

  return (
    <>
      <div style={{ padding: "5px 10px" }}>
        <h2 style={{ padding: "10px 20px" }}>
          Featured Products from{" "}
          <span style={{ color: "orange" }}>Verified Suppliers</span>
        </h2>
        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          pagination={{ clickable: true }}
          onSwiper={setSwiperInstance}
          onReachEnd={handleReachEnd}
          onReachBeginning={handleReachStart}
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 40 },
            1024: { slidesPerView: 7, spaceBetween: 30 },
          }}
          className="mySwiper"
        >
          {products.map((product) => (
            <SwiperSlide key={`product-${product.id}`}>
              <Link to={product.link}>
                <div className="card">
                  <img src={product.image} alt={product.name} />
                  <p>{product.name}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div style={{ padding: "0px 10px" }}>
        <Swiper
          slidesPerView={1}
          spaceBetween={5}
          pagination={{ clickable: true }}
          onSwiper={setSwiperInstance}
          onReachEnd={handleReachEnd}
          onReachBeginning={handleReachStart}
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 4, spaceBetween: 40 },
            1024: { slidesPerView: 7, spaceBetween: 30 },
          }}
          className="mySwiper"
        >
          {productsx.map((product) => (
            <SwiperSlide key={`product-${product.id}`}>
              <Link to={product.link}>
                <div className="card">
                  <img src={product.image} alt={product.name} />
                  <p>{product.name}</p>
                </div>
              </Link>
            </SwiperSlide>
          ))}
        </Swiper>

        {/* <div className="itdevice">
                    <img className='itimg' src="./assets/itwork.png" alt="itdevice" />
                </div> */}
        <div className="itdevice-container">
          <div className="itdevice">
            <img
              className="itimg"
              src="./assets/latestban.png"
              alt="itidevice"
            />
          </div>
          <div className="itdevice">
            <img
              className="itimg"
              src="./assets/latestban.png"
              alt="itidevice"
            />
          </div>
        </div>
      </div>
    </>
  );
}
